import React, { useState, useEffect } from "react";
import axios from "axios";
import { PieChart, Pie, Cell, Tooltip, Legend ,BarChart,Bar, XAxis, YAxis, CartesianGrid} from "recharts";
import { useLocation } from "react-router-dom";
import style from "./Org.css"
import { upload } from "@testing-library/user-event/dist/upload";
const DeviceSpecific=()=>{
    const [data, setData] = useState([]);
    const [FileData,setFileData]=useState([])
    const [loading, setLoading] = useState(true);
    const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#FF6384"];
    const location = useLocation();
    const [HoursMonitored,SetHoursMonitored]=useState(null)
    const { deviceid,date } = location.state || {}; // Colors for pie chart
    const [query,setQuery]=useState('')
    const [filteredResults, setFilteredResults] = useState([]);
    const[AvgRecordedToUpdated,setAvgRecordedToUpdated]=useState(null)
    const[AvgUpdatedToComputed,setUpdatedToComputed]=useState(null)
    
    
    const [lastSevenDaysData, setLastSevenDaysData] = useState([]);
    const [selectedMetric, setSelectedMetric] = useState("Unpaired");
     //console.log(deviceid,date)
    useEffect(() => {
        async function getData() {
            try {
                const response = await axios.get(
                    `https://genai.dozeeapp.com/device/files?DeviceId=${deviceid.DeviceId}&Date=${date}`
                );
                const stages = response.data?.Stages || {};
                console.log(response) 
                setFileData(response.data)// Assuming `Stages` is in the first record
                let {
                    RECORDED,
                    UNPAIRED,
                    UPLOADED,
                    NOTPROCESSABLE,
                    LOWFSR,
                    FINISHED_COMPUTE,
                } = stages;
                setAvgRecordedToUpdated(response.data['Time_between_Recorded_Uploaded']/UPLOADED)
                setUpdatedToComputed(response.data["Time_between_Uploaded_Compute"]/FINISHED_COMPUTE)

               
                const calculatedSum =
                    UNPAIRED + NOTPROCESSABLE + LOWFSR + FINISHED_COMPUTE;
                console.log(UNPAIRED)

                // Adjust UNPAIRED if needed
                // if (RECORDED < calculatedSum) {
                //     UNPAIRED -= RECORDED - calculatedSum;
                // }
                console.log(RECORDED)
                SetHoursMonitored(((FINISHED_COMPUTE*2)/60).toFixed(2))

                // Prepare data for the pie chart
                const chartData = [
                    { name: "Unpaired", value: UNPAIRED },
                    
                    { name: "Not Processable", value: NOTPROCESSABLE },
                    { name: "Low FSR", value: LOWFSR },
                    { name: "Finished Compute", value: FINISHED_COMPUTE },
                ];

                setData(chartData);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        }

        getData();
    }, [deviceid.DeviceId, date]);
    const fetchLastSevenDaysData = async (metric) => {
        setLoading(true);
        const lastSevenDays = Array.from({ length: 7 }, (_, i) => {
            const d = new Date();
            d.setDate(d.getDate() - i);
            return d.toISOString().split("T")[0];
        });

        try {
            const results = await Promise.all(
                lastSevenDays.map(async (day) => {
                    const response = await axios.get(
                        `https://genai.dozeeapp.com/device/files?DeviceId=${deviceid.DeviceId}&Date=${day}`
                    );
                    const stages = response.data?.Stages || {};
                    let {
                        UNPAIRED = 0,
                        NOTPROCESSABLE = 0,
                        LOWFSR = 0,
                        FINISHED_COMPUTE = 0,
                        RECORDED=0,
                        UPLOADED=0
                    } = stages;
                    for( let i=1;i<response.data.length;i++){
                        RECORDED+=response.data[i].Stages.RECORDED;
                        UNPAIRED+=response.data[i].Stages.UNPAIRED;
                        UPLOADED+=response.data[i].Stages.UPLOADED;
                        NOTPROCESSABLE+=response.data[i].Stages.NOTPROCESSABLE;
                        LOWFSR+=response.data[i].Stages.LOWFSR;
                        FINISHED_COMPUTE+=response.data[i].Stages.FINISHED_COMPUTE;
                    }

                    const totalSum = UNPAIRED + NOTPROCESSABLE + LOWFSR + FINISHED_COMPUTE;
                    let num=0
                    if (metric.toUpperCase()==="UNPAIRED"){
                        num=UNPAIRED
                    }
                    else if(metric.toUpperCase()==="NOTPROCESSABLE"){
                        num=NOTPROCESSABLE
                    }
                    else if(metric.toUpperCase()==="LOWFSR"){
                        num=LOWFSR
                    }
                    else if(metric.toUpperCase()==="FINISHEDCOMPUTE"){
                        num=FINISHED_COMPUTE
                    }
                    const percentage = totalSum ? ((num / totalSum) * 100).toFixed(2) : 0;

                    return {
                        date: day,
                        percentage: parseFloat(percentage),
                    };
                })
            );
            setLastSevenDaysData(results.reverse());
        } catch (error) {
            console.error("Error fetching last 7 days data:", error);
        } finally {
            setLoading(false);
        }
    };
    const handleMetricSelection = (metric) => {
        setSelectedMetric(metric);
        fetchLastSevenDaysData(metric);
    };
    const renderCustomLabel = ({ name, value, percent }) => {
        return `${name}: ${value} (${(percent * 100).toFixed(2)}%)`;
    };
   
    if (loading) {
        return <div>Loading...</div>;
    }

    if (data.length === 0) {
        return <div>No data found for the selected organization and date.</div>;
    }
    const handleItemClick=(item)=>{
        console.log(item)

    }
    return (
        <div className="container">
            <div className="chart-container">
            <h3>Recorded Data Breakdown for Date {date}  {deviceid.DeviceId}</h3>
            <PieChart width={800} height={400}>
                <Pie
                    data={data}
                    cx="50%"
                    cy="50%"
                    outerRadius={150}
                    fill="#8884d8"
                    dataKey="value"
                    label={renderCustomLabel}
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Tooltip contentStyle={{ backgroundColor: 'beige', color: 'white', border: '1px solid red' }} />
                <Legend />
            </PieChart>
            
            
            </div>
            <div>Total Hours Monitored {HoursMonitored}</div>
            <div>Avg Time taken for Upload to take place {AvgRecordedToUpdated} in seconds</div>
            <div>Avg Time take for Upload to Compute {AvgUpdatedToComputed} in seconds</div>
            <div className="bar-graph">
                <h3>Last 7 Days Data - {selectedMetric}</h3>
                <div>
                    <button onClick={() => handleMetricSelection("Unpaired")}>Unpaired</button>
                    <button onClick={() => handleMetricSelection("LowFSR")}>Low FSR</button>
                    <button onClick={() => handleMetricSelection("NotProcessable")}>
                        Not Processable
                    </button>
                    <button onClick={() => handleMetricSelection("FinishedCompute")}>
                        Finished Compute
                    </button>
                </div>
                <BarChart width={800} height={400} data={lastSevenDaysData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="percentage" fill="#8884d8" />
                </BarChart>
                </div>
            </div>
        )
}
export default DeviceSpecific