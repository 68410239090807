
// import React, { useEffect } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import Navbar from './components/Navbar';

// const Home = () => {
//   const navigate = useNavigate();

//   useEffect(() => {
//     // Check if the URL contains tokens
//     const hash = window.location.hash;
//     if (hash) {
//       const params = new URLSearchParams(hash.substring(1));
//       const accessToken = params.get('access_token');
//       const idToken = params.get('id_token');
//       const expiresIn = params.get('expires_in');
//       const tokenType = params.get('token_type');

//       if (accessToken && idToken && expiresIn && tokenType) {
//         // Store tokens in local storage
//         localStorage.setItem('access_token', accessToken);
//         localStorage.setItem('id_token', idToken);
//         localStorage.setItem('expires_in', expiresIn);
//         localStorage.setItem('token_type', tokenType);

//         // Clear the hash from the URL
//         window.location.hash = '';

//         // Optionally navigate to a specific route
//         navigate('/home');
//       }
//     }
//   }, [navigate]);

//   const handleDocsRedirect = () => {
//     navigate('/docs');
//   };

//   const handleCopyToClipboard = () => {
//     const idToken = localStorage.getItem('id_token');
//     if (idToken) {
//       navigator.clipboard.writeText(idToken).then(() => {
//         alert('ID token copied to clipboard!');
//       }).catch((err) => {
//         console.error('Failed to copy text: ', err);
//       });
//     } else {
//       alert('ID token not found in local storage.');
//     }
//   };

//   return (
//     <div>
     

//       <Navbar />
//       <li>
//             <Link to="/bcg">BcgViewer</Link>
//             <Link to="/device">BCGUpload</Link>
//           </li>
//           <button onClick={handleDocsRedirect}>Go to Docs</button>
//           <button onClick={handleCopyToClipboard}>Copy ID Token</button>
//     </div>
//   );
// };

// export default Home;

// src/components/Home.js
//https://genai.dozeeapp.com/oauth2/idpresponse
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from './components/Navbar';

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the URL contains tokens
    const hash = window.location.hash;
    if (hash) {
      const params = new URLSearchParams(hash.substring(1));
      const accessToken = params.get('access_token');
      const idToken = params.get('id_token');
      const expiresIn = params.get('expires_in');
      const tokenType = params.get('token_type');

      if (accessToken && idToken && expiresIn && tokenType) {
        // Store tokens in local storage
        localStorage.setItem('access_token', accessToken);
        localStorage.setItem('id_token', idToken);
        localStorage.setItem('expires_in', expiresIn);
        localStorage.setItem('token_type', tokenType);

        // Clear the hash from the URL
        window.location.hash = '';

        // Optionally navigate to a specific route
        navigate('/home');
      }
    }
  }, [navigate]);

  const handleDocsRedirect = () => {
    navigate('/docs');
  };

  const handleCopyToClipboard = () => {
    const idToken = localStorage.getItem('id_token');
    if (idToken) {
      navigator.clipboard.writeText(idToken)
        .then(() => {
          alert('ID token copied to clipboard!');
        })
        .catch((err) => {
          console.error('Failed to copy text: ', err);
        });
    } else {
      alert('ID token not found in local storage.');
    }
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div>
       <Navbar />
    <div className="home-container">
      
      
     
      <div className="button-grid">
        <button onClick={() => handleNavigation('/bcg')}>BcgViewer</button>
        <button onClick={() => handleNavigation('/updatetime')}>BCGUpload</button>
        <button onClick={handleDocsRedirect}>API docs</button>
        <button onClick={handleCopyToClipboard}>Copy ID Token</button>
        {/* <button onClick={()=>handleNavigation('/orgdata')}>DeviceData</button> */}
      </div>
    </div>
    </div>
  );
};

export default Home;
