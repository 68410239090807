import React, { useState, useEffect } from "react";
import axios from "axios";
import { PieChart, Pie, Cell, Tooltip, Legend ,BarChart,Bar, XAxis, YAxis, CartesianGrid} from "recharts";
import { useLocation } from "react-router-dom";
import style from "./Org.css"
import { useNavigate } from "react-router-dom";

const Org = () => {
    const [data, setData] = useState([]);
    const [FileData,setFileData]=useState([])
    const [loading, setLoading] = useState(true);
    const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#FF6384"];
    const location = useLocation();
    const [HoursMonitored,SetHoursMonitored]=useState(null)
    const { org,date } = location.state || {}; // Colors for pie chart
    const [query,setQuery]=useState('')
    const [filteredResults, setFilteredResults] = useState([]);
    const navigate = useNavigate();
    
    const [lastSevenDaysData, setLastSevenDaysData] = useState([]);
    const [selectedMetric, setSelectedMetric] = useState("Unpaired");
     console.log(org,date)
    useEffect(() => {
        async function getData() {
            try {
                const response = await axios.get(
                    `https://genai.dozeeapp.com/device/files/org?OrgId=${org.Orgid}&Date=${date}`
                );
                const stages = response.data[0]?.Stages || {}; 
                setFileData(response.data)// Assuming `Stages` is in the first record
                let {
                    RECORDED,
                    UNPAIRED,
                    UPLOADED,
                    NOTPROCESSABLE,
                    LOWFSR,
                    FINISHED_COMPUTE,
                } = stages;

                for( let i=1;i<response.data.length;i++){
                    RECORDED+=response.data[i].Stages.RECORDED;
                    UNPAIRED+=response.data[i].Stages.UNPAIRED;
                    UPLOADED+=response.data[i].Stages.UPLOADED;
                    NOTPROCESSABLE+=response.data[i].Stages.NOTPROCESSABLE;
                    LOWFSR+=response.data[i].Stages.LOWFSR;
                    FINISHED_COMPUTE+=response.data[i].Stages.FINISHED_COMPUTE;
                }

                const calculatedSum =
                    UNPAIRED + NOTPROCESSABLE + LOWFSR + FINISHED_COMPUTE;
                console.log(UNPAIRED)

                // Adjust UNPAIRED if needed
                // if (RECORDED < calculatedSum) {
                //     UNPAIRED -= RECORDED - calculatedSum;
                // }
                console.log(RECORDED)
                SetHoursMonitored((FINISHED_COMPUTE*2)/60)

                // Prepare data for the pie chart
                const chartData = [
                    { name: "Unpaired", value: UNPAIRED },
                    
                    { name: "Not Processable", value: NOTPROCESSABLE },
                    { name: "Low FSR", value: LOWFSR },
                    { name: "Finished Compute", value: FINISHED_COMPUTE },
                ];

                setData(chartData);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        }

        getData();
    }, [org.Orgid, date]);
    useEffect(()=>{
        if (query) {
            console.log(FileData)
            const results = FileData.filter(
                item =>
                    item.DeviceId.toLowerCase().includes(query.toLowerCase()) 
                    
            );
            console.log(results)
            setFilteredResults(results.slice(0,10));
        } else {
            setFilteredResults([]);
        }
    }, [query]);
    const fetchLastSevenDaysData = async (metric) => {
        setLoading(true);
        const lastSevenDays = Array.from({ length: 7 }, (_, i) => {
            const d = new Date();
            d.setDate(d.getDate() - i);
            return d.toISOString().split("T")[0];
        });

        try {
            const results = await Promise.all(
                lastSevenDays.map(async (day) => {
                    const response = await axios.get(
                        `https://genai.dozeeapp.com/device/files/org?OrgId=${org.Orgid}&Date=${day}`
                    );
                    const stages = response.data[0]?.Stages || {};
                    let {
                        UNPAIRED = 0,
                        NOTPROCESSABLE = 0,
                        LOWFSR = 0,
                        FINISHED_COMPUTE = 0,
                        RECORDED=0,
                        UPLOADED=0
                    } = stages;
                    for( let i=1;i<response.data.length;i++){
                        RECORDED+=response.data[i].Stages.RECORDED;
                        UNPAIRED+=response.data[i].Stages.UNPAIRED;
                        UPLOADED+=response.data[i].Stages.UPLOADED;
                        NOTPROCESSABLE+=response.data[i].Stages.NOTPROCESSABLE;
                        LOWFSR+=response.data[i].Stages.LOWFSR;
                        FINISHED_COMPUTE+=response.data[i].Stages.FINISHED_COMPUTE;
                    }

                    const totalSum = UNPAIRED + NOTPROCESSABLE + LOWFSR + FINISHED_COMPUTE;
                    let num=0
                    if (metric.toUpperCase()==="UNPAIRED"){
                        num=UNPAIRED
                    }
                    else if(metric.toUpperCase()==="NOTPROCESSABLE"){
                        num=NOTPROCESSABLE
                    }
                    else if(metric.toUpperCase()==="LOWFSR"){
                        num=LOWFSR
                    }
                    else if(metric.toUpperCase()==="FINISHEDCOMPUTE"){
                        num=FINISHED_COMPUTE
                    }
                    const percentage = totalSum ? ((num / totalSum) * 100).toFixed(2) : 0;

                    return {
                        date: day,
                        percentage: parseFloat(percentage),
                    };
                })
            );
            setLastSevenDaysData(results.reverse());
        } catch (error) {
            console.error("Error fetching last 7 days data:", error);
        } finally {
            setLoading(false);
        }
    };
    const fetchdevice=(metric)=>{
        let devices=[]
        for(let a=0;a<FileData.length;a++){
            let finished=FileData[a].Stages["FINISHED_COMPUTE"];
            let hours=(finished*2)/60
            if (hours<metric){
                devices.push(FileData[a].DeviceId)
            }
        }

    }

    const handleMetricSelection = (metric) => {
        setSelectedMetric(metric);
        fetchLastSevenDaysData(metric);
    };
    const renderCustomLabel = ({ name, value, percent }) => {
        return `${name}: ${value} (${(percent * 100).toFixed(2)}%)`;
    };
   
    if (loading) {
        return <div>Loading...</div>;
    }

    if (data.length === 0) {
        return <div>No data found for the selected organization and date.</div>;
    }
    const handleItemClick = (item) => {
        console.log(item)
        navigate('/device-details', { state: { deviceid: item ,date:date} }); // Pass data to the new route
    };
    

    return (
        <div className="container">
            <div className="chart-container">
            <h3>Recorded Data Breakdown for Date {date} and Org {org.Name}</h3>
            <PieChart width={800} height={400}>
                <Pie
                    data={data}
                    cx="50%"
                    cy="50%"
                    outerRadius={150}
                    fill="#8884d8"
                    dataKey="value"
                    label={renderCustomLabel}
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Tooltip contentStyle={{ backgroundColor: 'beige', color: 'white', border: '1px solid red' }} />
                <Legend />
            </PieChart>
            
            
            </div>
            <div className="bar-graph">
                <h3>Last 7 Days Data - {selectedMetric}</h3>
                <div>
                    <button onClick={() => handleMetricSelection("Unpaired")}>Unpaired</button>
                    <button onClick={() => handleMetricSelection("LowFSR")}>Low FSR</button>
                    <button onClick={() => handleMetricSelection("NotProcessable")}>
                        Not Processable
                    </button>
                    <button onClick={() => handleMetricSelection("FinishedCompute")}>
                        Finished Compute
                    </button>
                </div>
                <BarChart width={800} height={400} data={lastSevenDaysData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="percentage" fill="#8884d8" />
                </BarChart>
            </div>
            <input
            type="text"
            placeholder="Search by Name or OrgId"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
        />
        
        
       {query &&  <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
            {filteredResults.map((item) => (
                <div
                    key={item.Deviceid}
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '8px',
                        border: '1px solid #ccc',
                        marginBottom: '5px',
                    }}
                    onClick={() => handleItemClick(item)}
                >
                    <span>{item.DeviceId}</span>
                    
                </div>
            ))}
            
            </div>
       }
        
        </div>
    );
};

export default Org;
