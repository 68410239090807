import React from "react";
import { useState,useEffect } from "react"; 
import axios from "axios";
import style from "./Device.css"
import Org from "./Org";
import { useNavigate } from "react-router-dom";
const Device=()=>{
    const navigate = useNavigate();
    const[Devicefiles,setFiles]=useState([]);
    const [query, setQuery] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);
    const [selectedItem,setSelectedItem]=useState(null)
    const[Date,setDate]=useState("")
    useEffect(()=>{
        async function getOrgData(){
        const response = await axios.get(
            'https://genai.dozeeapp.com/device/org',
           
          );
          console.log(response)
          setFiles(response.data)
          console.log(Devicefiles)
        }
        getOrgData()
        

    },[])
    useEffect(()=>{
        if (query) {
            const results = Devicefiles.filter(
                item =>
                    item.Name.toLowerCase().includes(query.toLowerCase()) ||
                    item.Orgid.includes(query)
            );
            setFilteredResults(results.slice(0,10));
        } else {
            setFilteredResults([]);
        }
    }, [query]);
    const handleItemClick = (item) => {
        navigate('/org-details', { state: { org: item ,date:Date} }); // Pass data to the new route
    };
    

    return (

    

        <>

<div className="org-container">
    <input type="text" placeholder="Enter date"
    value={Date} onChange={(e)=>setDate(e.target.value)}>
   

    </input>
        <input
            type="text"
            placeholder="Search by Name or OrgId"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
        />
        
       {query &&  <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
            {filteredResults.map((item) => (
                <div
                    key={item.Orgid}
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '8px',
                        border: '1px solid #ccc',
                        marginBottom: '5px',
                    }}
                    onClick={() => handleItemClick(item)}
                >
                    <span>{item.Name}</span>
                    <span>{item.Orgid}</span>
                </div>
            ))}
            {selectedItem && Date && <Org org={selectedItem} date={Date}></Org>}
            </div>
       }
        
    
    </div>
        
        
        
        
        </>
    )

}
export default Device
